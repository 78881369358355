import { Box, Grid } from '@lojinha/design-system'
import React from 'react'
import { sanitizeImage } from '@lojinha/helpers'
import { t } from '../../../dictionary'
import {
  OnItemClickType,
  OnRenderItemType,
  OriginList,
} from '../../../modules/display/types'
import { DisplayItems } from '../../../modules/page-content/page-content.types'
import { ProductCardIndividual } from '../../product-card'
import { User } from '@lojinha/context/src/types'

type ProductCardListProps = {
  name?: string
  items: DisplayItems[]
  originList: OriginList
  onItemClick?: OnItemClickType
  onRenderItem?: OnRenderItemType
  user?: User
}

export const ProductCardList = ({
  name,
  items,
  originList,
  onItemClick,
  onRenderItem,
  user,
}: ProductCardListProps) => {
  return (
    <Grid
      gridTemplateColumns={[
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ]}
      gridGap="x16"
      gridRowGap="x32"
    >
      {items.map((item, index) => {
        return (
          <Box key={item.id} height={['312px', '408px']}>
            <ProductCardIndividual
              id={item.id}
              kind={item.kind}
              classification={item.classification}
              title={item.name}
              badges={item.itemTags.nodes}
              isAvailable={item.isAvailable}
              isAgeRestricted={item.isAgeRestricted}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: 'medium',
              })}
              price={item.price || 0}
              sku={item.sku}
              priceFrom={item.priceFrom || 0}
              weight={t.weightAndUnit(item.weight, item.unit)}
              imageAriaLabel={item.name}
              eventList={originList}
              positionInList={index}
              onRenderItem={() =>
                onRenderItem &&
                onRenderItem({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              onItemClick={() =>
                onItemClick &&
                onItemClick({
                  id: item.id,
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  originList: originList,
                  isAvailable: item.isAvailable,
                  user,
                })
              }
              slug={item.seo.url}
              displayName={name}
            />
          </Box>
        )
      })}
    </Grid>
  )
}
