import { DisplayItems } from '../modules/page-content/page-content.types'

export const sortedItemsByAvailability = (items: DisplayItems[]) =>
  [...items].sort((a, b) => {
    if (a.isAvailable && b.isAvailable) {
      return 0
    }
    if (a.isAvailable) {
      return -1
    }
    if (b.isAvailable) {
      return 1
    }
    return 0
  })
