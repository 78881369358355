import { t } from '../../dictionary'
import { Menu } from '../../types'

type MenuStrapi = Menu & {
  children?: MenuStrapi[]
}

export const MENU_DATA: MenuStrapi[] = [
  {
    id: '6c66e930-9777-4ea6-8e19-0e6d9d1c2a4e',
    title: t.packedLunchs,
    route: '/marmitas',
    isNew: false,
    children: [
      {
        id: 'e109ff72-13a8-4935-a51e-06fb8b7d8a0b',
        title: t.dayToDay,
        route: '/marmitas#dia-a-dia',
        isNew: false,
      },
      {
        id: '10e0ce09-4ea8-4659-9092-59713976c3f5',
        title: t.turbocharged,
        route: '/marmitas#turbinadas',
        isNew: false,
      },
      {
        id: 'b5d5b340-c0ab-4f37-a5a3-e65f35992d81',
        title: t.fitness,
        route: '/marmitas#fitness',
        isNew: false,
      },
      {
        id: '3e332b4d-fb39-42cf-a163-53700d49fbc5',
        title: t.vegetarians,
        route: '/marmitas#vegetarianas',
        isNew: false,
      },
      {
        id: 'bea6e119-bf0c-4f48-a677-6d76fd419061',
        title: t.pasta,
        route: '/marmitas#massas',
        isNew: false,
      },
      {
        id: 'ce5de458-d94e-4d6d-92ff-283187edddd5',
        title: t.specials,
        route: '/marmitas#especiais',
        isNew: false,
      },
      {
        id: 'c48791c7-3e56-4e01-bf7e-e6f9b8f51ee6',
        title: t.kits,
        route: '/marmitas#kits',
        isNew: false,
      },
      {
        id: 'd1c56a29-d087-4b07-9acc-24e4150f6f46',
        title: t.seeAll,
        route: '/marmitas',
        isNew: false,
      },
    ],
  },
  {
    id: '21a169e0-c232-46e8-aabd-18a40a3abbfa',
    title: t.moreCategories,
    route: '/mais-categorias',
    isNew: false,
    children: [
      {
        id: 'da24dd33-5bde-4655-8384-f6c920b87062',
        title: t.proteins,
        route: '/mais-categorias#proteinas',
        isNew: false,
      },
      {
        id: 'cee89825-20f1-4bf6-8769-c4dd9ff5df2d',
        title: t.sideDishes,
        isNew: false,
        route: '/mais-categorias#acompanhamentos',
      },
      {
        id: 'bbf3368d-4727-4d2d-84d7-fe763ffad6ce',
        title: t.savory,
        isNew: false,
        route: '/mais-categorias#salgados',
      },
      {
        id: 'd3d18009-0970-4681-954b-d3c22f53623f',
        title: t.sweets,
        isNew: false,
        route: '/mais-categorias#doces',
      },
      {
        id: '00e15f4f-d2b2-4a01-832a-34c8b571f8f1',
        title: t.soups,
        route: '/mais-categorias#sopas',
        isNew: false,
      },
      {
        id: '13582895-cebe-47ac-8ec5-18ec843eeab3',
        title: t.proteinSnacks,
        route: '/mais-categorias#lanches-proteicos',
        isNew: false,
      },
      {
        id: '141c5302-6e44-4d07-a27b-f730056469c7',
        title: t.seeAll,
        route: '/mais-categorias',
        isNew: false,
      },
    ],
  },
  {
    id: 'cfe45756-339e-4f24-bcb8-3bc0260221c3',
    title: t.goals.all,
    isNew: false,
    route: '/objetivos',
    children: [
      {
        id: 'fed1c7b9-9679-400e-af81-9bb5a5da6b88',
        title: t.goals.weightLoss,
        route: '/emagrecimento',
        isNew: false,
      },
      {
        id: 'a93d6e4a-8a83-41d0-87a9-c7c13b00e519',
        title: t.goals.meatLessDiet,
        route: '/dieta-sem-carne',
        isNew: false,
      },
      {
        id: '2fbf2d96-33f9-4716-997c-531d52c334ea',
        title: t.goals.comfortFood,
        route: '/comfort-food',
        isNew: false,
      },
      {
        id: '5cd053fa-8efe-4547-aa2a-820460b4b1df',
        title: t.goals.lunchOutside,
        route: '/marmitar-fora-de-casa',
        isNew: false,
      },
      {
        id: '7dd30523-8aef-4eb0-86c5-f067879f747b',
        title: t.goals.healthySnack,
        route: '/lanchar-saudavel',
        isNew: false,
      },
      {
        id: '406062e6-f4b5-4f8b-973c-5ac16a93a540',
        title: t.seeAll,
        isNew: false,
        route: '/objetivos',
      },
    ],
  },
  {
    id: '8bf312e3-7b40-43e8-b799-72218a5e42d9',
    title: 'A Liv Up',
    route: '/dna-liv-up',
    isNew: false,
    children: [
      {
        id: '8b9fa047-9f4b-44e9-b54a-35bfb556493c',
        title: 'Sobre a Liv Up',
        route: '/dna-liv-up',
        isNew: false,
      },
      {
        id: '0dae3cce-388b-44db-9066-19119ec2df7f',
        title: 'Como funciona',
        isNew: false,
        route: '/como-funciona',
      },
      {
        id: 'dded55fb-f5ef-4781-989e-ac9f9400143b',
        title: 'Formas de pagamento',
        route: '/formas-de-pagamento',
        isNew: false,
      },
    ],
  },
]
