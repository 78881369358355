import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Center, Grid, Text } from '@lojinha/design-system'
import { MarketableKind } from '@lojinha/palantir'
import { KitCardList } from '../../components/card-list/kit'
import { MealCardList } from '../../components/card-list/meal'
import { ProductCardList } from '../../components/card-list/product'
import { t } from '../../dictionary'
import { sortedItemsByAvailability } from '../../helpers/products'
import { OriginList } from '../display/types'
import { DisplayItems } from '../page-content/page-content.types'

const Container = styled(Box)<{ showGradient: boolean }>(
  ({ showGradient, theme }) => `
  background: ${
    showGradient
      ? 'linear-gradient(to bottom,#f6f3ea 0%,rgba(255, 255, 255, 0) min(544px, 100%))'
      : 'none'
  };
  padding-top: ${!showGradient ? theme.space.x20 : ''};
`
)

type Props = {
  results: Array<{
    kind: MarketableKind
    items: DisplayItems[]
  }>
  term: string
  totalCount: number
}

export const SearchResults: FC<Props> = ({ results, term, totalCount }) => {
  const getListByKind = ({
    items,
    kind,
  }: {
    items: DisplayItems[]
    kind: MarketableKind
  }) => {
    if (kind === MarketableKind.Product) {
      return (
        <ProductCardList
          items={sortedItemsByAvailability(items)}
          originList={OriginList.SEARCH}
        />
      )
    }

    if (kind === MarketableKind.Kit) {
      return (
        <KitCardList
          items={sortedItemsByAvailability(items)}
          originList={OriginList.SEARCH}
        />
      )
    }

    return (
      <MealCardList
        items={sortedItemsByAvailability(items)}
        originList={OriginList.SEARCH}
      />
    )
  }

  return (
    <>
      <Center>
        <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
          <Grid gridColumn={['2 / 24', '2 / 12']}>
            <Box marginTop={['x24', 'x56']}>
              <Text>
                <Text isBrand textTransform="uppercase" as="text">
                  {t.search.searchingFor}
                </Text>
                <Text as="text">"{term}"</Text>
              </Text>
              <Text color="gray700">{t.searchItemsCount(totalCount)}</Text>
            </Box>
          </Grid>
        </Grid>
      </Center>

      {results.map(({ items, kind }, i) => {
        if (!items.length) return null
        return (
          <Container
            key={i}
            display="flex"
            showGradient={i !== 0}
            paddingTop={['x40', 'x80']}
            paddingBottom={['x24', 'x20']}
          >
            <Center htmlAttrs={{ style: { width: '100%' } }}>
              <Grid
                gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
                htmlAttrs={{ style: { width: '100%' } }}
              >
                <Grid gridColumn={['2 / 24', '2 / 12']}>
                  {getListByKind({ items, kind })}
                </Grid>
              </Grid>
            </Center>
          </Container>
        )
      })}
    </>
  )
}
